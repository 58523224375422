import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch,useLocation, Route } from "react-router-dom";
import Home from "./core/Home";
import Menu from "./core/Menu";
import { isAuthenticated, isAdmin } from "./functions/ReuseableFunctions";
import"../src/assets/css/general.css"


const Routes = () => {
	const [timer, setTimer] = useState(true);
	const [admin, setAdmin] = useState(false);

    const load = () => {
		setTimer(false);
		setTimeout(() => {
			setTimer(true);
		}, 100);
	};

    useEffect(() => {
        setTimeout(() => {
			isAdmin().then( (val) => {
				setAdmin(val);
			});
		}, 1000);
       
    }, [timer])


  return (
      <>
    <BrowserRouter>
    <div style={{ overflow: "hidden" }}>
    <Menu load={load}/>
        <Switch >
        <div class="horizontalouter-wrapper">
      <div class="horizontalwrapper">
      <Route path="/" exact  component={Home} />
      </div>
      </div>
      </Switch>
     </div>
    </BrowserRouter>

    </>
  );
};

export default Routes;
