import React, { useState, useEffect } from "react";

import Base from "./Base";

import { isAuthenticated, isAdmin } from "../functions/ReuseableFunctions";
import { useMediaQuery } from "react-responsive";
import HomeSection from "../components/Home/HomeSection";
import Contact from "../components/Reusablecomponent/contact";
import { motion, AnimatePresence } from "framer-motion";
import Edit from "./Edit_Call";
import { Helmet } from "react-helmet";
import { db } from "./firebase/firebase";
import Footer from "../components/Reusablecomponent/footer";
import Loader from "../components/Reusablecomponent/loader";

import "aos/dist/aos.css";

export default function Home() {
  const [database_val, setdatabase_val] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [edit_value, setEdit_value] = useState([]);
  const [Edit_Activated, setEdit_Activated] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1200px)" });
  const [timer, setTimer] = useState(true);

  const fetch_database_val = async () => {
    db.collection("Home")
      .doc("8MUtAtm0UTNWcnDOLXyx")
      .get()
      .then((response) => {
        const database_value = {
          id: response.id,
          ...response.data(),
        };
        console.log(database_value);
        setdatabase_val(database_value);
      })
      .catch((error) => {
        console.log(error);
      });
    const auth = await isAuthenticated();
    if (auth) {
      console.log(auth.id);
      setAdmin(auth);
    }
  };

  const activate = (name) => {
    console.log(name);
    setEdit_Activated(true);
    setEdit_value(name);
  };

  const deactivate = () => {
    setEdit_Activated(false);

    setTimeout(() => {
      fetch_database_val();
    }, 2000);
  };

  const acceptedValue = (val, keyone) => {
    var key = keyone.split(".");
    setdatabase_val({
      ...database_val,
      [key[0]]: { ...database_val[key[0]], [key[1]]: val },
    });
  };

  const Add_array_Value = (val, keyone) => {
    console.log(val);
    var key = keyone.split(".");
    setdatabase_val({
      ...database_val,
      [key[0]]: {
        ...database_val[key[0]],
        [key[1]]: [...database_val[key[0]][key[1]], val],
      },
    });
  };

  const Remove_array_Value = (val, keyone) => {
    var st = keyone;
    var key = keyone.split(".");
    setdatabase_val({
      ...database_val,
      [key[0]]: {
        ...database_val[key[0]],
        [key[1]]: database_val[key[0]][key[1]].filter((item) => item !== val),
      },
    });
  };



  const top = () => {
      window.scrollTo({
          top: 0,
          left: 0,
      });
  };

function loaded(){
    setTimeout(() => {
        setTimer(false);
    }, 2200);
}


  useEffect(() => {
    fetch_database_val()
    setTimeout(() => {
      isAdmin().then((val) => {
        setAdmin(val);
      });
    }, 1000);
  }, []);

  const reload = () => {
    setTimeout(() => {
      isAdmin().then(function (val) {
        setAdmin(val);
      });
    }, 1000);
  };


  return (
    <>
      <Helmet>
        <title>Larangel-One stop digital Solution</title>
      </Helmet>

      {timer &&
					<Loader /> }
				
					
        {database_val.HomeSection && (
          <>
            <HomeSection
              activate={activate}
              admin={admin}
              id={database_val.id}
              HomeSection={database_val.HomeSection}
              load={loaded}
              timer={timer}
            />

<Contact/>
<Footer/>
</>)}

         

        <AnimatePresence>
          {Edit_Activated && isDesktopOrLaptop && admin && (
            <>
              <motion.div
                className="popup-box_edit"
                initial={{ x: -500, opacity: 1 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -500, opacity: 1 }}
                transition={{ duration: 0.8 }}
              >
                <div
                  className="box_edit"
                  style={{
                    marginBottom: "100px",
                  }}
                >
                  <Edit
                    collection={"Home"}
                    edit_value={edit_value}
                    deactivate={deactivate}
                    acceptValue={acceptedValue}
                    Add_array_Value={Add_array_Value}
                    Remove_array_Value={Remove_array_Value}
                  ></Edit>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
    </>
  );
}
