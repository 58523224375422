import { useState, useEffect } from "react";
import { db, storage, firebase } from "../core/firebase/firebase";
import { useMediaQuery } from "react-responsive";
import { motion, useMotionValue } from "framer-motion";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { ToastContainer, toast } from "react-toastify";
import validator from "validator";

export function isAuthenticated() {
	return new Promise(function (resolve, reject) {
		if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
			// Additional state parameters can also be passed via URL.
			// This can be used to continue the user's intended action before triggering
			// the sign-in operation.
			// Get the email if available. This should be available if the user completes
			// the flow on the same device where they started it.
			var email = window.localStorage.getItem("emailForSignIn");
			if (!email) {
				// User opened the link on a different device. To prevent session fixation
				// attacks, ask the user to provide the associated email again. For example:
				email = window.prompt("Please provide your email for confirmation");
			}
			// The client SDK will parse the code from the link for you.
			firebase
				.auth()
				.signInWithEmailLink(email, window.location.href)
				.then((result) => {
					// Clear email from storage.
					var user = firebase.auth().currentUser;
					resolve(user);
					// You can access the new user via result.user
					// Additional user info profile not available via:
					// result.additionalUserInfo.profile == null
					// You can check if the user is new or existing:
					// result.additionalUserInfo.isNewUser
				})
				.catch((error) => {
					// Some error occurred, you can inspect the code: error.code
					// Common errors could be invalid email and invalid or expired OTPs.
				});
		}
		var user = firebase.auth().currentUser;

		if (user) {
			db.collection("user")
				.doc(user.uid)
				.get()
				.then((response) => {
					var data = { ...response.data() };
					const myArr = user.email.split("@");
					var userdata = {};
					if (!user.displayName) {
						userdata = {
							...data,
							email: user.email,
							name: myArr[0],
							userid: user.uid,
						};
					} else {
						userdata = {
							...data,
							email: user.email,
							name: user.displayName,
							userid: user.uid,
						};
					}
					db.collection("user").doc(user.uid).set(userdata);
					if (myArr[1] == "evqpoint.com") {
						db.collection("Admin_user_data")
							.doc("TvAQlQdZEcdoI7V0jHLN")
							.update({
								user_val: firebase.firestore.FieldValue.arrayUnion(user.uid),
							});
					}

					resolve(user);
				});
		}
	});
}

export function isAdmin() {
	return new Promise(function (resolve, reject) {
		var user = firebase.auth().currentUser;
		db.collection("Admin")
			.doc("4ESMOgHfdctZaKaenYqC")
			.get()
			.then((response) => {
				var admins = { ...response.data() };
				admins = admins.admins;
				var n = admins.includes(user.uid);
                console.log('hi')
                console.log(n)
				if (n) {
					resolve(user);
				}
				resolve(false);
			})
			.catch((error) => {
				console.log(error);
			});
	});
}

export function login() {

	return new Promise(function (resolve, reject) {
		const provider = new firebase.auth.GoogleAuthProvider();
		const data = firebase.auth().signInWithPopup(provider);
		if (data.email !== null) {
			resolve(data);
        
		}
	});
}

export function logout() {
	const toastifylogout = () => {
		toast.error("logout Sucessfull!", {
			position: "bottom-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback danger",
			toastId: "notifyToast",
		});
	};
	return new Promise(function (resolve, reject) {
		const data = firebase.auth().signOut();
		window.localStorage.removeItem("emailForSignIn");
		resolve(true);
        setTimeout(() => {
            toastifylogout();
        }, 2000);
	});
}

export function emaillogin(email) {
	return new Promise(function (resolve, reject) {
		console.log(email);
		var actionCodeSettings = {
			url: "http://localhost:3000",
			// This must be true.
			handleCodeInApp: true,
		};

		console.log(actionCodeSettings);

		firebase
			.auth()
			.sendSignInLinkToEmail(email, actionCodeSettings)
			.then(() => {
				console.log("emaillogin sent");
				window.localStorage.setItem("emailForSignIn", email);
				resolve(true);
			})
			.catch((error) => {
				var errorCode = error.code;
				var errorMessage = error.message;
				resolve(false);
			});
	});
}

export function Edit_Single_Text(Value, key, id, activate, Start, End) {
	var scr = window.innerWidth;

	return (
		<>
			<div
				style={{ cursor: "default" }}
				onClick={() =>
					activate({
						id: id,
						value: Value,
						textedit: true,
						key: key,
						start: Start,
						end: End,
					})
				}
			>
				{Value ? (
					<>
						{scr > 1200 && (
							<div
								style={{
									paddingRight:
										Value.font_paddingRight && Value.font_paddingRight + "px",
									paddingLeft:
										Value.font_paddingLeft && Value.font_paddingLeft + "px",
									paddingTop:
										Value.font_paddingTop && Value.font_paddingTop + "px",
									paddingBottom:
										Value.font_paddingBottom && Value.font_paddingBottom + "px",
									marginLeft: Value.font_pos && Value.font_pos + "px",
									letterSpacing: Value.font_ls && Value.font_ls + "px",
									fontWeight: Value.font_weight && Value.font_weight,
									fontSize: Value.font_size && Value.font_size + "px",
									color: Value.color && Value.color,
								}}
							>
								{Value.text}
							</div>
						)}

						{scr < 1200 && (
							<div
								style={{
									letterSpacing: Value.font_ls && Value.font_ls + "px",
									fontWeight: Value.font_weight && Value.font_weight,
									color: Value.color && Value.color,
								}}
							>
								{Value.text}
							</div>
						)}
					</>
				) : (
					<>*****</>
				)}
			</div>
		</>
	);
}

export function Edit_Single_number(Value, key, id, activate, Start, End) {
	var scr = window.innerWidth;

	return (
		<>
			<div
				style={{ cursor: "default" }}
				onClick={() =>
					activate({
						id: id,
						value: Value,
						key: key,
						start: Start,
						end: End,
					})
				}
			>
				{scr > 1200 && (
					<div>
						<CountUp end={Value.text} redraw={true}>
							{({ countUpRef, start }) => (
								<VisibilitySensor onChange={start} delayedCall>
									<div className="row text-white justify-content-center">
										<div
											className="fh2 col-auto"
											style={{
												paddingRight:
													Value.font_paddingRight &&
													Value.font_paddingRight + "px",
												paddingLeft:
													Value.font_paddingLeft &&
													Value.font_paddingLeft + "px",
												paddingTop:
													Value.font_paddingTop && Value.font_paddingTop + "px",
												paddingBottom:
													Value.font_paddingBottom &&
													Value.font_paddingBottom + "px",
												marginLeft: Value.font_pos && Value.font_pos + "px",
												letterSpacing: Value.font_ls && Value.font_ls + "px",
												fontWeight: Value.font_weight && Value.font_weight,
												fontSize: Value.font_size && Value.font_size + "px",
												color: Value.color && Value.color,
											}}
										>
											{" "}
											<span ref={countUpRef} />{" "}
										</div>
										<div
											className="fh3 col-2 my-auto "
											style={{ padding: "0px" }}
										>
											<div
												style={{
													color: Value.color && Value.color,
												}}
											>
												+
											</div>
										</div>
									</div>
								</VisibilitySensor>
							)}
						</CountUp>
					</div>
				)}

				{scr < 1200 && (
					<CountUp end={Value.text} redraw={true}>
						{({ countUpRef, start }) => (
							<VisibilitySensor onChange={start} delayedCall>
								<div className="row text-white justify-content-center">
									<div
										className="fh2 col-auto"
										style={{
											fontWeight: Value.font_weight && Value.font_weight,
											color: Value.color && Value.color,
										}}
									>
										{" "}
										<span ref={countUpRef} />{" "}
									</div>
									<div
										className="fh3 col-2 my-auto "
										style={{
											color: Value.color && Value.color,
										}}
										style={{ padding: "0px" }}
									>
										+
									</div>
								</div>
							</VisibilitySensor>
						)}
					</CountUp>
				)}
			</div>
		</>
	);
}

export function Edit_Image(Value, key, id, activate, class_name, alttext) {
	return (
		<>
			<div
				style={{ cursor: "default" }}
				onClick={() =>
					activate({
						id: id,
						img_key: key,
						IMG_Value_add: Value,
					})
				}
				onDrag={() =>
					activate({
						id: id,
						img_key: key,
						IMG_Value_edit: Value,
					})
				}
			>
				{Value.imgurl ? (
					<img
						src={Value.imgurl}
						className={class_name}
						alt={alttext}
						style={{ borderRadius: Value.rad && Value.rad + "%" }}
					/>
				) : (
					<>O</>
				)}
			</div>
		</>
	);
}



export function Animated_image(image1,image2,class_name) {

    const { ref, inView, entry } = useInView({
        threshold: 0,
      });

	return (
		<>		
        {inView ? (
					<img ref={ref}
						src={image1}
						className={class_name}
					/>
				) : (
					<img ref={ref}
						src={image2}
						className={class_name}
					/>
				)}
		</>
	);
}













//validation_function
//validation for first name lastname
export function validation_name(value) {
	var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;
	if (value == "" || value != "not_selected") {
		console.log(value);
		if (value) {
			if (!value.startsWith(" ")) {
				if (value.length >= 1) {
					if (!format.test(value)) {
						if (value.length <= 50) {
							if (!value.endsWith(" ")) {
								return {
									class: "pass",
								};
							} else
								return {
									class: "warn",
									msg: (
										<>
											<small class="text-danger">
												Cannot end with a white space
											</small>
										</>
									),
								};
						} else
							return {
								class: "warn",
								msg: (
									<>
										<small class="text-danger">Max letter 50</small>
									</>
								),
							};
					} else
						return {
							class: "warn",
							msg: (
								<>
									<small class="text-danger">
										Cannot contain symbol or number.
									</small>
								</>
							),
						};
				} else
					return {
						class: "warn",
						msg: (
							<>
								<small class="text-danger">Min 1 tetter.</small>
							</>
						),
					};
			} else
				return {
					class: "warn",
					msg: (
						<>
							<small class="text-danger">Cannot start with empty space</small>
						</>
					),
				};
		} else
			return {
				class: "warn",
				msg: (
					<>
						<small class="text-danger">This field is a required.</small>
					</>
				),
			};
	}
	if (value == "not_selected") return "";
}
//contact form title
export function validation_title(value) {
	if (value == "" || value != "not_selected") {
		console.log(value);
		if (value) {
			if (!value.startsWith(" ")) {
				if (value.length >= 1) {
					if (value.length <= 150) {
						if (!value.endsWith(" ")) {
							return {
								class: "pass",
							};
						} else
							return {
								class: "warn",
								msg: (
									<>
										<small class="text-danger">
											Cannot end with white space
										</small>
									</>
								),
							};
					} else
						return {
							class: "warn",
							msg: (
								<>
									<small class="text-danger">Max length is 150</small>
								</>
							),
						};
				} else
					return {
						class: "warn",
						msg: (
							<>
								<small class="text-danger">Min length is 1</small>
							</>
						),
					};
			} else
				return {
					class: "warn",
					msg: (
						<>
							<small class="text-danger">Cannot start with white space</small>
						</>
					),
				};
		} else return "";
	}
	if (value == "not_selected") return "";
}

export function validation_mobile_number(value) {
	if (value == "" || value != "not_selected") {
		console.log(value);
		var phoneno = /^\d{10}$/;
		if (value) {
			if (phoneno.test(value)) {
				return {
					class: "pass",
				};
			} else {
				return {
					class: "warn",
					msg: (
						<>
							<small class="text-danger">
								Please Enter A Valid Phone Number
							</small>
						</>
					),
				};
			}
		} else
			return {
				class: "warn",
				msg: (
					<>
						<small class="text-danger">This field is a required.</small>
					</>
				),
			};
	}
	if (value == "not_selected") return "";
}
//company validation
export function validation_company(value) {
	if (value == "" || value != "not_selected") {
		console.log(value);
		if (value) {
			if (!value.startsWith(" ")) {
				if (value.length >= 1) {
					if (value.length <= 50) {
						if (!value.endsWith(" ")) {
							return {
								class: "pass",
							};
						} else
							return {
								class: "warn",
								msg: (
									<>
										<small class="text-danger">
											Cannot end with white space
										</small>
									</>
								),
							};
					} else
						return {
							class: "warn",
							msg: (
								<>
									<small class="text-danger">Max length is 150</small>
								</>
							),
						};
				} else
					return {
						class: "warn",
						msg: (
							<>
								<small class="text-danger">Min length is 1</small>
							</>
						),
					};
			} else
				return {
					class: "warn",
					msg: (
						<>
							<small class="text-danger">Cannot start with white space</small>
						</>
					),
				};
		} else
			return {
				class: "warn",
				msg: (
					<>
						<small class="text-danger">This field is a required.</small>
					</>
				),
			};
	}
	if (value == "not_selected") return "";
}
// email validation
export function validation_email(value) {
	if (value == "" || value != "not_selected") {
		console.log(value);
		var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{3,4})+$/; //{3,4}is used to change the count of word after .com or net
		if (value) {
			if (regex.test(value)) {
				return {
					class: "pass",
				};
			} else {
				return {
					class: "warn",
					msg: (
						<>
							<small class="text-danger">Please enter a valid E-mail</small>
						</>
					),
				};
			}
		} else {
			return {
				class: "warn",
				msg: (
					<>
						<small class="text-danger">This field is a required.</small>
					</>
				),
			};
		}
	}
	if (value == "not_selected") return "";
}
//country validation

export function validation_country(value) {
	var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;
	if (value == "" || value != "not_selected") {
		console.log(value);
		if (value) {
			if (!value.startsWith(" ")) {
				if (value.length >= 1) {
					if (value.length <= 56) {
						if (!value.endsWith(" ")) {
							if (!format.test(value)) {
								return {
									class: "pass",
								};
							} else
								return {
									class: "warn",
									msg: (
										<>
											<small class="text-danger">invalid name</small>
										</>
									),
								};
						} else
							return {
								class: "warn",
								msg: (
									<>
										<small class="text-danger">
											Cannot end with white space
										</small>
									</>
								),
							};
					} else
						return {
							class: "warn",
							msg: (
								<>
									<small class="text-danger">Max length is 56</small>
								</>
							),
						};
				} else
					return {
						class: "warn",
						msg: (
							<>
								<small class="text-danger">Min length is 1</small>
							</>
						),
					};
			} else
				return {
					class: "warn",
					msg: (
						<>
							<small class="text-danger">Cannot start with white space</small>
						</>
					),
				};
		} else return "warn";
	}
	if (value == "not_selected") return "warn";
}

// validation for inquiry
export function validation_inquiry(value) {
	if (value == "" || value != "not_selected") {
		console.log(value);
		if (value) {
			if (!value.startsWith(" ")) {
				if (value.length >= 1) {
					if (value.length <= 50) {
						if (!value.endsWith(" ")) {
							return {
								class: "pass",
							};
						} else
							return {
								class: "warn",
								msg: (
									<>
										<small class="text-danger">
											Cannot end with white space
										</small>
									</>
								),
							};
					} else
						return {
							class: "warn",
							msg: (
								<>
									<small class="text-danger">Max length is 150</small>
								</>
							),
						};
				} else
					return {
						class: "warn",
						msg: (
							<>
								<small class="text-danger">Min length is 1</small>
							</>
						),
					};
			} else
				return {
					class: "warn",
					msg: (
						<>
							<small class="text-danger">Cannot start with white space</small>
						</>
					),
				};
		} else return "";
	}
	if (value == "not_selected") return "";
}

//comment validation
export function validation_comment(value) {
	if (value == "" || value != "not_selected") {
		console.log(value);
		if (value) {
			if (!value.startsWith(" ")) {
				if (value.length >= 1) {
					if (value.length <= 250) {
						if (!value.endsWith(" ")) {
							return {
								class: "pass_text_area",
							};
						} else
							return {
								class: "warn_text_area",
								msg: (
									<>
										<small class="text-danger">
											Cannot end with white space
										</small>
									</>
								),
							};
					} else
						return {
							class: "warn_text_area",
							msg: (
								<>
									<small class="text-danger">Max length is 250</small>
								</>
							),
						};
				} else
					return {
						class: "warn_text_area",
						msg: (
							<>
								<small class="text-danger">Min length is 1</small>
							</>
						),
					};
			} else
				return {
					class: "warn_text_area",
					msg: (
						<>
							<small class="text-danger">Cannot start with white space</small>
						</>
					),
				};
		} else
			return {
				class: "warn_text_area",
				msg: (
					<>
						<small class="text-danger">This field is a required.</small>
					</>
				),
			};
	}
	if (value == "not_selected") return "";
}
export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}
