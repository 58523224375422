import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import { Link } from "react-scroll";
import { motion, useMotionValue } from "framer-motion";
import "../../assets/css/homepage/Home.css";
import "../../assets/css/general.css";
import "../../assets/css/Card/uiux.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import uiux from "../../assets/image/uiux.svg";
import V1 from "../../assets/image/Larangelvideo.mp4";
import Card from "./card.js";
import OurteamCard from "./Ourteamcard.js";
import {
	Edit_Single_Text,
	Edit_Image,
} from "../../functions/ReuseableFunctions";
import dataSecurity from "../../assets/image/data-security.svg";
import product from "../../assets/image/women-globe.svg";
import cms from "../../assets/image/CMS.svg";
import BS from "../../assets/image/BS.png";
import Springin from "../../assets/image/Springin.png";
import Economize from "../../assets/image/Economize.png";
import Evqpt from "../../assets/image/Evqpt.png";
import Nexigo from "../../assets/image/Nexigo.png";
import responsiveLayout from "../../assets/image/RL.svg";
import DesignRevisions from "../../assets/image/DesignRevisions.svg";
import UIUX from "../../assets/image/uiux.svg";
import UI from "../../assets/image/front.svg";
import CC from "../../assets/image/cc.svg";
import BE from "../../assets/image/be.svg";
import TEST from "../../assets/image/uiux.svg";
import HOST from "../../assets/image/host.svg";
import DM from "../../assets/image/dm.svg";
import IOT from "../../assets/image/iot.svg";
import { isAuthenticated, isAdmin } from "../../functions/ReuseableFunctions";
import { db } from "../../core/firebase/firebase";
import { AnimatePresence } from "framer-motion";
import Edit from "../../core/Edit_Call";



export default function HomeSection(props) {
	const isTabletOrMobile = useMediaQuery({ query: "(min-width: 950px)" });
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1228px)" });
	const [unique, setUnique] = useState({
		webApp: true,
		mobileApp: false,
		crm: false,
		customisedSoftware: false,
	});
	const [database_val, setdatabase_val] = useState([]);
	const [admin, setAdmin] = useState(false);
	const [edit_value, setEdit_value] = useState([]);
	const [Edit_Activated, setEdit_Activated] = useState(false);
	const [timer, setTimer] = useState(true);


	const [ref, inView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});

	const [ref1, inView1] = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	const ani_mate1 = {
		visible: { opacity: 1, scale: 1 },
		hidden: {
			opacity: 0,
			scale: 0.8,
		},
	};

	const [ref2, inView2] = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	const ani_mate2 = {
		visible: { opacity: 1, scale: 1 },
		hidden: {
			opacity: 0,
			scale: 0.8,
		},
	};

	const [ref3, inView3] = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	const ani_mate3 = {
		visible: { opacity: 1, scale: 1 },
		hidden: {
			opacity: 0,
			scale: 0.8,
		},
	};

	const fetch_database_val = async () => {
		db.collection("Home")
		  .doc("8MUtAtm0UTNWcnDOLXyx")
		  .get()
		  .then((response) => {
			const database_value = {
			  id: response.id,
			  ...response.data(),
			};
			console.log(database_value);
			setdatabase_val(database_value);
		  })
		  .catch((error) => {
			console.log(error);
		  });
		const auth = await isAuthenticated();
		if (auth) {
		  console.log(auth.id);
		  setAdmin(auth);
		}
	  };
	
	  const activate = (name) => {
		console.log(name);
		setEdit_Activated(true);
		setEdit_value(name);
	  };
	
	  const deactivate = () => {
		setEdit_Activated(false);
	
		setTimeout(() => {
		  fetch_database_val();
		}, 2000);
	  };
	
	  const acceptedValue = (val, keyone) => {
		var key = keyone.split(".");
		setdatabase_val({
		  ...database_val,
		  [key[0]]: { ...database_val[key[0]], [key[1]]: val },
		});
	  };
	
	  const Add_array_Value = (val, keyone) => {
		console.log(val);
		var key = keyone.split(".");
		setdatabase_val({
		  ...database_val,
		  [key[0]]: {
			...database_val[key[0]],
			[key[1]]: [...database_val[key[0]][key[1]], val],
		  },
		});
	  };
	
	  const Remove_array_Value = (val, keyone) => {
		var st = keyone;
		var key = keyone.split(".");
		setdatabase_val({
		  ...database_val,
		  [key[0]]: {
			...database_val[key[0]],
			[key[1]]: database_val[key[0]][key[1]].filter((item) => item !== val),
		  },
		});
	  };
	
	
	
	  const top = () => {
		  window.scrollTo({
			  top: 0,
			  left: 0,
		  });
	  };
	
	function loaded(){
		setTimeout(() => {
			setTimer(false);
		}, 2200);
	}
	
	
	  useEffect(() => {
		fetch_database_val()
		setTimeout(() => {
		  isAdmin().then((val) => {
			setAdmin(val);
		  });
		}, 1000);
	  }, []);
	
	  const reload = () => {
		setTimeout(() => {
		  isAdmin().then(function (val) {
			setAdmin(val);
		  });
		}, 1000);
	  };

	function handleClick(val) {
		setUnique({
			webApp: false,
			mobileApp: false,
			crm: false,
			customisedSoftware: false,
			[val]: true,
		});
	}

	const settings = {
		speed: 3000,
		autoplay: true,
		autoplaySpeed: 2000,
		slidesToShow: 5,
		slidesToScroll:2,
		pauseOnHover: false,
        dots: true,

		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
				},
			},
		],
	};
	return (
		<div className='bgclr'>
			<div id="OneStopDigital"></div>
			<div style={{ height: isDesktopOrLaptop && "90vh" }} className="one ">
				<div className="row pt-lg-3 ">
					<div className="col-lg-6 col-md-12 pt-lg-5 pb-5  pl-lg-5 pl-4">
						<div className="OneStopText bold text-left">
							Adapt to the fast paced
							<div className={!props.timer && "santy"}>digital world</div>
						</div>
						<div className="h5 pt-2" style={{ lineHeight: "1.5" }}>
							<span className="h5" style={{ fontWeight: "400" }}>
								Larangel{" "}
							</span>
							is a Pioneer in leading cost-effective development at a shorter
							time span. We handle simple static websites to complex softwares.
						</div>
						<Link to="Amenities" spy={true} smooth={true} offset={-100}>
							<div className="larangel_button mt-3">Learn more</div>
						</Link>
					</div>

					<div onLoad={props.load()} className="col-lg-6 col-md-12  pt-5">
						{Edit_Image(
							props.HomeSection.OneStopImg1,
							"HomeSection.OneStopImg1",
							props.id,
							props.activate,
							"img-fluid text-center",
							"LarangelOneStopSolutionImage1"
						)}
					</div>
				</div>
				<div className=" d-flex justify-content-evenly container pr-lg-5 pl-lg-5 mt-5 ">
					<div>
						<a href="http://springinservices.com/" target="_blank">
							<img src={Springin} className="img-fluid" />
						</a>
					</div>
					<div>
						<a href="https://battlesoccers.pythonanywhere.com/" target="_blank">
							<img src={BS} className="img-fluid" />
						</a>
					</div>

					<div>
						<a href="https://evqpoint.com/" target="_blank">
							<img src={Evqpt} className="img-fluid Clientimgev" />
						</a>
					</div>

					<div>
						<a href="https://nexigo-8be1d.web.app/" target="_blank">
							<img src={Nexigo} className="img-fluid" />
						</a>
					</div>
					{isDesktopOrLaptop && (
						<div>
							<a href="https://www.economize.cloud/" target="_blank">
								<img src={Economize} className="img-fluid" />
							</a>
						</div>
					)}
				</div>
			</div>

			<div id="Amenities"></div>
			<div style={{ height: isDesktopOrLaptop && "90vh" }} className="one">
				<div className="row mb-5">
					<div className="col-lg-12 mt-lg-5 mt-3">
						<div className="OneStopText  container">
							<span className="part1">One Stop S</span>
							<span>olution for all your </span>
							<span>Digital Infrastructure Needs</span>
						</div>
					</div>
				</div>
				<div className="row mt-lg-5 mt-3">
					<div className="mt-5 pt-4">
						<Slider {...settings}>
							<Card heading="UX/UI" subheading="Design & Redesign" img={UIUX} />
							<Card heading="Frontend" subheading="UI development" img={UI} />
							<Card heading="Content creation" subheading="CMS,DAS" img={CC} />
							<Card heading="Adv Backends" subheading="API,Cloud" img={BE} />
							<Card
								heading="Testing"
								subheading="7 level of testing "
								img={TEST}
							/>
							<Card
								heading="Devops"
								subheading="SSL, Data security"
								img={HOST}
							/>
							<Card
								heading="Digital Marketing"
								subheading="SEM, SEO, SMM"
								img={DM}
							/>
							<Card heading="IOT" subheading="Cloud based sys" img={IOT} />
						</Slider>
					</div>
				</div>
			</div>

			<div id="OurExpertise"></div>
			<div style={{ height: isDesktopOrLaptop && "90vh" }} className="one">
				<div className="OneStopText"></div>
				<div className="row">
					<motion.div
						animate={!isDesktopOrLaptop || inView1 ? "visible" : "hidden"}
						variants={ani_mate1}
						transition={{ duration: 1 }}
						ref={ref1}
						className="col-lg-6 col-md-12"
					>
						{Edit_Image(
							props.HomeSection.OneStopImg,
							"HomeSection.OneStopImg",
							props.id,
							props.activate,
							"img-fluid text-center pt-5",
							"LarangelOneStopSolutionImage1"
						)}
					</motion.div>

					<div className="col-lg-5 col-md-12 mt-lg-5 pr-5 pl-5 pt-5 p-lg-0 offset-lg-1">
						<div className="d-flex flex-column">
							<div>
								<div
									className="h4 mb-3"
									style={{ fontWeight: "600" }}
									onClick={() => handleClick("webApp")}
								>
									Web App
								</div>
								{unique.webApp && (
									<div>
										Larangel builds the latest progressive web apps with admin{" "}
										<br></br> controls where elegant front end meets complex
										backend. We
										<br></br> have a wide variety of experience in this field.
									</div>
								)}
							</div>
							<div>
								<div
									className="h4 mt-5 mb-3"
									style={{ fontWeight: "600" }}
									onClick={() => handleClick("mobileApp")}
								>
									Mobile App
								</div>
								{unique.mobileApp && (
									<div>
										Best mobile apps with modern features and cross platform
										nature. <br></br>Build once and reach billions of customers
										both on IOS and
										<br></br>Android devices.
									</div>
								)}
							</div>
							<div>
								<div
									className="h4 mt-5 mb-3"
									style={{ fontWeight: "600" }}
									onClick={() => handleClick("crm")}
								>
									CRM
								</div>
								{unique.crm && (
									<div>
										We make CRM technologies by understanding customer business{" "}
										<br></br> requirements. We design and develop them in a way
										that ensures
										<br></br> easy usage and maximum results.
									</div>
								)}
							</div>
							<div>
								<div
									className="h4 mt-5 mb-3"
									style={{ fontWeight: "600" }}
									onClick={() => handleClick("customisedSoftware")}
								>
									Customized Software
								</div>
								{unique.customisedSoftware && (
									<div>
										We are the experts when it comes to building customised
										softwares <br></br>Our SA/BD connect with customers ,
										convert requirements to prototype
										<br></br>and bring ideas to life.
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="Didyouknowbg p-lg-3 pt-5 pb-5 mt-4  mb-lg-5">
				<div className="row mb-lg-5 ">
					<div className="DidyouKnowText">
						<span className="part4">DID YOU KNOW ?</span>
					</div>
					<div className="col-12 ">
						<div className="sub_text2 container-lg ">
							Only 30% of the businesses have their digital presence. Only 5% of
							those businesses appear on google search results. Imagine billions
							of people visiting just 5% of businesses , their revenue grows
							exponentially. Want to be among the 5% of businesses ? Larangel is
							there with you to empower your digital prescence and make a mark.
						</div>
					</div>
				</div>
			</div>

			<div id="Technology"></div>
			<div style={{ height: isDesktopOrLaptop && "55vh" }} className="one ">
				<div className="mt-lg-5">
					<div className="row pt-lg-4 mt-lg-5 d-flex justify-content-center">
						<motion.div
							animate={!isDesktopOrLaptop || inView2 ? "visible" : "hidden"}
							variants={ani_mate2}
							transition={{ duration: 1 }}
							ref={ref2}
							className="col-lg-5 col-12 pl-5 pr-5 pt-lg-5 mt-lg-5"
						>
							<div className="OneStopText bold text-lg-start ">
								Signature specials
							</div>
							<div className="h6 pt-4 " style={{ lineHeight: "1.5" }}>
								<span className="h5 " style={{ fontWeight: "600" }}>
									Larangel{"  "}
								</span>
								has a range of unique features to be availed and much more. Each
								feature is tailored to suit a specific customer's business
								needs.
							</div>
						</motion.div>

						<div className="col-lg-6 col-12  pr-lg-5 p-4">
							<div className="d-flex justify-content-evenly mt-5 mb-5">
								<div className=" ">
									<img
										src={DesignRevisions}
										className="img-fluid pr-3 pl-2 pb-1"
									/>
									<div class="text-center bold1">Design Revisions</div>
								</div>
								<div className=" ">
									<img
										src={responsiveLayout}
										className="img-fluid pr-3 pl-2 pb-2"
									/>
									<div class="text-center bold1 ">Responsive</div>
								</div>
								<div className=" ">
									<img src={cms} className="img-fluid pr-3 pl-2 pb-1" />
									<div class="text-center bold1 ">CMS</div>
								</div>
							</div>
							<div className="d-flex justify-content-evenly mt-5">
								<div className=" ">
									<img src={product} className="img-fluid pr-3 pl-2 pb-1" />
									<div class="text-center bold1">Renown services</div>
								</div>
								<div className=" ">
									<img
										src={dataSecurity}
										className="img-fluid pr-3 pl-2 pb-1"
									/>
									<div class="text-center bold1">Data security</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div style={{ height: isDesktopOrLaptop && "55vh" }} className="one ">
				<div className="row mt-lg-5 mt-3">
					<div
						className="col-12 col-lg-6 pt-lg-5 order-lg-1 order-2 "
						ref={ref}
						style={{ paddingLeft: isTabletOrMobile && "130px" }}
					>
						<ReactPlayer
							muted={true}
							url={V1}
							controls={false}
							playing={inView}
							width={isTabletOrMobile ? "35vw" : "100vw"}
						/>
					</div>
					<motion.div
						animate={!isDesktopOrLaptop || inView3 ? "visible" : "hidden"}
						variants={ani_mate3}
						transition={{ duration: 1 }}
						ref={ref3}
						className="col-lg-5 col-12 pl-5 pr-5 pt-5 mt-lg-5 order-lg-2 order-1"
					>
						<div className="OneStopText bold ">24/7/365 Support</div>
						<div className="h6 pt-4" style={{ lineHeight: "1.5" }}>
							<span className="h5 " style={{ fontWeight: "600" }}>
								Larangel{" "}
							</span>
							's customer support team is available at all times to ensure your{" "}
							business running smoothly . Our dedicated team's core principle of
							customer obsession drives us to keep achieving more.
						</div>
					</motion.div>
				</div>
			</div>

			<div id="OurTeam"></div>
			<div style={{ height: isDesktopOrLaptop && "100vh" }} className="Ourteam">
				<div className="row mb-5" style={{ paddingTop: "50px" }}>
					<div className="OneStopText ">
						<span className="part2">Our Team</span>
					</div>
					<div className="col-12">
						<div className="sub_text1 container-lg">
							We at larangel are a passionate and young work force who loves
							challenges, skilled on the latest cutting edge technologies . With
							such a strong diverse team of 12+ people working in perfect
							synchronization , we are able to hone and achieve all deliverables
							to the customers.Our Rapidly growing team enables us to handle an
							entire digital business with minimal effort. We have innovated and
							improved on the smallest of tasks which in turn sculpts the entire
							project.
						</div>
					</div>
				</div>
			</div>

			{database_val.OurteamSection && (
			<OurteamCard 
			  activate={activate}
              admin={admin}
              id={database_val.id}
              OurteamSection={database_val.OurteamSection}
			/>
			)}

			<AnimatePresence>
          {Edit_Activated && isDesktopOrLaptop && admin && (
            <>
              <motion.div
                className="popup-box_edit"
                initial={{ x: -500, opacity: 1 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -500, opacity: 1 }}
                transition={{ duration: 0.8 }}
              >
                <div
                  className="box_edit"
                  style={{
                    marginBottom: "100px",
                  }}
                >
                  <Edit
                    collection={"Home"}
                    edit_value={edit_value}
                    deactivate={deactivate}
                    acceptValue={acceptedValue}
                    Add_array_Value={Add_array_Value}
                    Remove_array_Value={Remove_array_Value}
                  ></Edit>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
		</div>

		
	);
}
