import React, { useState, Fragment } from "react";
import {Link} from 'react-scroll';
import "bootstrap/dist/css/bootstrap.min.css";
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core'
import { FaTools,FaBuffer,FaGithub,FaReact,FaGraduationCap,FaHeadphonesAlt,FaPhoneVolume,FaHeadset } from "react-icons/fa";
import { FcExpand } from "react-icons/fc";
import "../../assets/css/nav_bar_css/hamnav.css";
import { FcCollapse } from "react-icons/fc";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import { motion} from "framer-motion";

const Hamnav = (props) => {

  return (
    <div
    onClick={props.handlenav}
      className=" bg-white hamna"
      style={{ height: "100vh", width: "100vw", float:"right"}}
    >
     <br></br>
<div class="navb">
    <ul class="lin mt-4">

    {/* Products */}
    
    <li onClick={props.handlenav} class="navhea mt-2 mb-5">
    <FaTools/>
     <Link to="OneStopDigital"  onClick={props.handlenav}>  Services</Link>
    </li>
    {/* <li>
     <a onClick={() => setpdc(!pdc)} class="navit ml-3">Battery Packs {pdc? <FcExpand/>:<FcCollapse/>}</a>
            <ul class="submenu" style={{display: pdc ? "none" : "" }}>
            <li onClick={props.handlenav}><Link to="/Undercons">VIGOR</Link></li>
            <li onClick={props.handlenav}><Link to="/Undercons">VIVID</Link></li>
            </ul>
    </li> 
    <li>
     <a onClick={() => setpac(!pac)} class="navit ml-3">BMS {pac? <FcExpand/>:<FcCollapse/>}</a>
            <ul class="submenu" style={{display: pac ? "none" : "" }}>
            <li onClick={props.handlenav}><Link to="/Undercons">Industrial</Link></li>
            <li onClick={props.handlenav}><Link to="/Undercons">Automotive</Link></li>
            </ul>    
     </li>  */}
    
     {/* Home and commercial solutions */}
     <li onClick={props.handlenav} class="navhea mt-2 mb-5">
     <FaBuffer/>
     <Link to="OurExpertise" onClick={props.handlenav}>  Products</Link>
    </li>
     {/* <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Light Electric Vehicle </Link>
    </li> 

    <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Industrial Vehicles </Link>
    </li> 

    <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Energy Storage </Link>
    </li>  */}
    
     
    {/* Business Solutions */}
    <li onClick={props.handlenav} class="navhea mt-2 mb-5">
    <FaGithub />
     <Link to="Technology" onClick={props.handlenav}><span> Technology</span></Link>
    </li>

    {/* <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Ultra Fast Charging </Link>
    </li> 
    
    <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Best in Class </Link>
    </li> 

    <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Ultra Capacitors </Link>
    </li> 

    <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Battery management system </Link>
    </li>  */}
   
    
     <li onClick={props.handlenav} class="navhea mt-3 mb-5">
     <FaGraduationCap />
     <Link to="OurTeam" onClick={props.handlenav}> About</Link>
    </li>

    {/* <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Custom Battery design </Link>
    </li> 
    
    <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">LAB services </Link>
    </li> 

    <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Fast to market </Link>
    </li> 

    <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Sourcing and supply chain </Link>
    </li>  */}

    <li onClick={props.handlenav} class="navhea mt-3 mb-3">
    <span style={{position:"relative",bottom:'3px'}}><FaHeadphonesAlt/></span>
     <Link to="ContactUs" onClick={props.handlenav}> Contact Us</Link>
    </li>

    {/* <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Vision & Mission </Link>
    </li> 
    
    <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Core Values </Link>
    </li> 

    <li onClick={props.handlenav} class="navit ml-3"><Link to="/Undercons">Leadership </Link>
    </li>  */}

    {/* <li onClick={props.handlenav} class="navhea mt-3 mb-3">
     <Link to="/Contact">CONTACT US</Link>
    </li> */}

    {/* <li onClick={props.handlenav} class="navhea mt-3 mb-2">
     <a onClick={props.request_toggle}>REQUEST QUOTES</a>
    </li> */}
    
  </ul>
</div>
{/* <div onClick={props.send(!props.val)}></div> */}
</div>
     
  );
  

};

export default Hamnav;
