import React, { useState, useEffect } from "react";
import "../../assets/css/Ourteamcard/card.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
	Edit_Single_Text,
	Edit_Image,
} from "../../functions/ReuseableFunctions";

export default function OurteamCard(props) {
  return (
    <>
      <div className="row m-2 mt-3 mb-3">
        <div className="col-lg-3 col-sm-12 Ourteamtitlecontainer">
            <div className="Ourteamtitle text-center">DESIGNERS</div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.OurteamSection.img1,
							"OurteamSection.img1",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">YASMEEN BEGAM</div>
                <div class="Ourteam-sub-header">UX/UI Designer</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img2,
							"OurteamSection.img2",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">JAMEEN APROSE</div>
                <div class="Ourteam-sub-header">UX/UI Designer</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img3,
							"OurteamSection.img3",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">SUDDIKSHA</div>
                <div class="Ourteam-sub-header">UX/UI Designer</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-2 mb-3">
        <div className="col-lg-3 col-sm-12 Ourteamtitlecontainer">
        <div className="Ourteamtitle  text-center">FRONT END</div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img4,
							"OurteamSection.img4",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">RIZWANA BANU</div>
                <div class="Ourteam-sub-header">Front End Developer</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img5,
							"OurteamSection.img5",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">PRIYADHARSHINI</div>
                <div class="Ourteam-sub-header">Front End Developer</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3"></div>
      </div>

      <div className="row m-2 mb-3">
        <div className="col-lg-3 col-sm-12 Ourteamtitlecontainer">
        <div className="Ourteamtitle  text-center">BACK END</div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img6,
							"OurteamSection.img6",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">VIBISH KUMAR</div>
                <div class="Ourteam-sub-header">Back End Developer</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img7,
							"OurteamSection.img7",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">SANTOSH SIVARAMAN</div>
                <div class="Ourteam-sub-header">Back End Developer</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3"></div>
      </div>

      <div className="row m-2 mb-3">
        <div className="col-lg-3 col-sm-12 Ourteamtitlecontainer">
        <div className="Ourteamtitle  text-center">FULL STACK</div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img8,
							"OurteamSection.img8",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">KAAMIL MOHAMMED</div>
                <div class="Ourteam-sub-header">Full Stack Developer</div>
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="col-lg-3 col-md-4  col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img9,
							"OurteamSection.img9",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">SANTHOSH KUMAR</div>
                <div class="Ourteam-sub-header">Full Stack Developer</div>
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="col-lg-3"></div>
      </div>

      <div className="row m-2 mb-3">
        <div className="col-lg-3  col-sm-12 Ourteamtitlecontainer">
        <div className="Ourteamtitle  text-center">INTERN</div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img10,
							"OurteamSection.img10",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">MOHAMMED</div>
                <div class="Ourteam-sub-header">Intern</div>
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img11,
							"OurteamSection.img11",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">YOGESH KUMAR</div>
                <div class="Ourteam-sub-header">Intern</div>
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="Ourteamcard">
            <div class="Ourteam-body">
              <div class="Ourteamcardimg p-3">
              {Edit_Image(
							props.
                            OurteamSection.img12,
							"OurteamSection.img12",
							props.id,
							props.activate,
							"img-fluid Ourteamcardimg",
							"LarangelTeamImages"
						)}
              </div>
              <div class="Ourteamcardheadsection text-center">
                <div class="Ourteam-header">SWETHA</div>
                <div class="Ourteam-sub-header">Intern</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
