import React, { useState, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import google from "../../assets/image/google.png";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { AiOutlineClose } from "react-icons/ai";

import {
	isAuthenticated,
	login,
	logout,
} from "../../functions/ReuseableFunctions";
import validator from "validator";
import { emaillogin } from "../../functions/ReuseableFunctions";





const Signinnav = (props) => {
	const [logmailsent, setlogmailsent] = useState(false);

	const [values, setValues] = useState({
		email: "",
	});
	const { email } = values;

	const handleChange = (name) => (event) => {
		setValues({ ...values, error: false, [name]: event.target.value });
	};

	const login_with_email = async () => {
		if (validator.isEmail(values.email)) {
			emaillogin(values.email).then(() => {
				toastifySuccess();
				setlogmailsent(true);
			});
		} else {
			toastifyfail();
		}
	};

	const toastifyfail = () => {
		toast.error("Please enter vaild  details", {
			position: "bottom-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	const toastifySuccess = () => {
		toast.info("Check your mail for login link", {
			position: "top-center",
			autoClose: false,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};


    function Success_popup() {
        return (
            <div className="d-inline-flex">
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ scale: 0.65, opacity: 0 }}
                    transition={{ duration: 1 }}
                    className="row d-flex justify-content-center "
                >
                    <div className="col-auto ">
                        <div className="fh2"></div>
                        <div className="">
                            <img
                                width="25%"
                                src="https://icons.veryicon.com/png/o/miscellaneous/cloud-call-center/success-24.png"
                                className=" "
                            />
                            <div className="fh4 p-lg-4 pr-lg-5 pl-lg-5 p-2">
                                Check your mail for a single click login link from EvqPoint !!{" "}
                            </div>
                            <div className="fh3 mt-3"></div>
                        </div>
                    </div>
                </motion.div>
            </div>
        );
    }

	return (
		<>
			{logmailsent ? (
				<>
					<div className="row">
						<div className="col-lg-3 offset-lg-8  col-md-5 offset-md-6 col-10 offset-1 text-center">
							<div
								className="text-center shadow bg-white logincard"
							>
								<div className="row ">
									<div
										onClick={() => props.close_login_popup()}
										style={{ cursor: "pointer" }}
										className="col-12 text-right p-2 pr-4"
									>
										{/* <img src={ic} /> */}
										<AiOutlineClose />
									</div>
                                    <div
										className="col-12 p-1"
									>
                                    {Success_popup()}
								</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					{props.userin ? (
						<>
							<div>
								<div className="row ">
									<div className="col-lg-3 offset-lg-8  rounded col-md-5 offset-md-6 col-12 text-center">
										<div
											className="text-center bg-white rounded  shadow logincard"
										>
											<div className="row ">
												<div
													onClick={() => props.close_login_popup()}
													style={{ cursor: "pointer" }}
													className="col-12 text-right p-2 pr-4"
												>
													{/* <img src={ic} /> */}
													<AiOutlineClose />
												</div>
												<div
													className="col-12 text-center txtstyle"
													style={{ marginTop: "-10px" }}
												>
													You are logged in as{" "}
												</div>
											</div>

											<div className="mt-3 txt4">
												{props.userin.photoURL ? (
													<img
														className="usr mr-2"
														src={props.userin.photoURL}
													></img>
												) : (
													<img
														className="usr mr-2"
														src="https://freepngimg.com/thumb/google/66726-customer-account-google-service-button-search-logo.png"
													></img>
												)}
												{props.userin.displayName}
											</div>
											<div className="mt-3 em_txt">{props.userin.email}</div>
											<div className="col-12">
												<div
													className="p-1 mt-4 mb-3 button text-white "
													onClick={props.logout}
												>
													Logout
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="row">
								<div className="col-lg-4 offset-lg-7  rounded col-md-6 offset-md-5 col-12 text-center">
									<div
										
										className="text-center bg-white rounded shadow logincard"
									>
										<div className="row ">
											<div
												onClick={() => props.close_login_popup()}
												style={{ cursor: "pointer" }}
												className="col-12 text-right p-2 pr-4"
											>
												{/* <img src={ic} /> */}
												<AiOutlineClose />
											</div>
											<div
												className="col-12 text-center txtstyle"
												style={{ marginTop: "-5px" }}
											>
												Welcome back, Please login to your account
											</div>
											<div className=" "> {/* <img src={exit} /> */}</div>
										</div>
										<div className="row mt-2 text-center">
											<div
												className="col-12  text-center mt-1  mb-1     "
												onClick={props.login}
											>
												<div
													className=" pr-4 pl-4 bg-light  btn-sm btn"
													style={{ borderRadius: "24px" }}
												>
													<img src={google} className=" pr-3" />
													<span className="text-dark">
														Continue with Google
													</span>
												</div>
											</div>

											<div className="col-12 text-center mt-2 mb-1 ">
												or<br></br>
												<br></br>
												Please enter your E-mail Id to continue
												<div className="row d-flex justify-content-center container ">
													<div className="col-auto text-center  mt-3">
														<input
															type="email"
															name="email"
															value={email}
															onChange={handleChange("email")}
															className="form-control  formInput"
															placeholder="Email address"
														></input>
													</div>

													<div className="col-auto text-center ">
														<input
															onClick={() => login_with_email()}
															type="submit"
															value="send"
															className="btn btn-primary btn-small  mt-3 "
														/>
													</div>
												</div>
											</div>
										</div>

										{/* <div className="col-auto text-center ">
												<input
													onClick={() => props.onsigninnavLeave()}
													type="submit"
													value="close"
													className="btn btn-light btn-sm  mt-2 "
												/>
											</div> */}

										<div className="col-12 mt-4 txt1">You are signed out</div>
										<div className="col-12 pb-3 txt2">
											Sign in to get the best experience
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
};

export default Signinnav;
