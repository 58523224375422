import React, { useState, useEffect, useRef } from "react";
import {Link} from 'react-scroll';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../assets/image/Larangel.svg";
import "../../assets/css/nav_bar_css/Navbarbigscreen.css";
import { motion, AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import Signinnav from "./signinnav";
import "../../assets/css/general.css";
import $ from "jquery";

import {
  isAdmin,
  isAuthenticated,
  login,
  logout,
  emaillogin,
} from "../../functions/ReuseableFunctions";

export default function Navbarbigscreen(props) {
  const [userdata, setUserdata] = useState("");
  const [checklogged, setChecklogged] = useState(false);
  const [isadmin, setAdmin] = useState("");
  const [current_page, setCurrentpage] = useState("home");

  const toastifylogout = () => {
    toast.error("logout Sucessfull!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback danger",
      toastId: "notifyToast",
    });
  };

  const toastifylogin = () => {
    toast.success("login sucessfull!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const call_logout = async () => {
    logout().then(function (log) {
      if (log)
        setTimeout(() => {
          setUserdata("");
          setAdmin(false);

          toastifylogout();
        }, 2000);
    });
  };

  const call_login = async () => {
    login().then(function (log) {
      if (log != null)
        setTimeout(() => {
          isAuthenticated().then(function (val) {
            if (val) {
              console.log(val);
              setUserdata({ ...val });
              isAdmin().then(function (val) {
                setAdmin(val);
              });
            }
          });
          toastifylogin();
        }, 2000);
    });
  };

  function close_login_popup() {
    setChecklogged(!checklogged);
  }

  useEffect(() => {
    setTimeout(() => {
      isAuthenticated().then(function (val) {
        if (val) {
          setUserdata({ ...val });
        }
      });
    }, [1500]);
  }, [checklogged]);

  let Navbarheight = $(".Navbarbigscreen").height();
  console.log(Navbarheight);

  return (
    <>
      <div className="Navbarbigscreen bg-white shadow">
        <div className="row ">
          <div className="col-2 pl-xl-5">
            <div>
              <Link to="OneStopDigital" spy={true} smooth={true} offset={-100}>
                <LazyLoadImage
                  onClick={() => setCurrentpage("home")}
                  src={logo}
                  className="navlogo"
                />
              </Link>
            </div>
          </div>
          <div className="col-10">
            <div className="row justify-content-end">
              <div className="col-auto pr-4">
                <Link to="OneStopDigital" spy={true} smooth={true} offset={-100}
                  onClick={() => setCurrentpage("home")}
                  className={
                    current_page == "home" ? "active_nav" : "navstyle"
                  }
                >
                  <div>Services</div>
                </Link>
              </div>
              <div className="col-auto pr-4">
                <Link
                  to ="OurExpertise" spy={true} smooth={true} offset={-100}
                  onClick={() => setCurrentpage("Products")}
                  className={
                    current_page == "Products" ? "active_nav" : "navstyle"
                  }
                >
                  <div>Products</div>
                </Link>
              </div>
              <div className="col-auto pr-4">
                <Link to ="Technology" spy={true} smooth={true} offset={-100} 
                onClick={() => setCurrentpage("Technology")}
                className={
                    current_page == "Technology" ? "active_nav" : "navstyle"
                  }>
                  <div>Technology</div>
                </Link>
              </div>
              <div className="col-auto pr-5 mr-xl-5 ">
                <Link to="OurTeam" spy={true} smooth={true} offset={-100}
                  onClick={() => setCurrentpage("about")}
                  className={
                    current_page == "about" ? "active_nav" : "navstyle"
                  }
                >
                  <div>About</div>
                </Link>
              </div>
              <div className="col-auto  pl-5 pr-1">
                <Link to ="ContactUs" spy={true} smooth={true} offset={-100} 
                onClick={() => setCurrentpage("contact")}
                className={
                    current_page == "contact" ? "nav_button" : "nav_button"
                  }>
                  <div className="contactbtn">Contact Us</div>
                </Link>
              </div>
              {userdata && (
                <div
                  className="col-auto"
                  onClick={close_login_popup}                >
                  <Link className="navuser pr-xl-5 pr-lg-2">
                    <img onClick={close_login_popup}     className="user" src={userdata.photoURL}></img>
                  </Link>
                </div>
              )}

              {!userdata && (
                <div
                  className="col-auto"
                  onClick={close_login_popup}
                >
                  <Link className="nav_button_login pr-xl-5 pr-lg-3">
                    <div onClick={close_login_popup} className="loginbtn">Login</div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {checklogged && (
          <motion.div
            initial={{ x: 700, opacity: 1 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 700, opacity: 1 }}
            transition={{ duration: 0.6 }}
            className="sticky"
            style={{ Zindex: "1!important" }}
          >
            <Signinnav
              close_login_popup={close_login_popup}
              login={call_login}
              logout={call_logout}
              userin={userdata}
            ></Signinnav>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
