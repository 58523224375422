import { useForm } from "react-hook-form";
import React, { useState } from "react";
import validator from "validator";
import { emaillogin } from "../../functions/ReuseableFunctions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { motion, useMotionValue } from "framer-motion";
import VisibilitySensor from "react-visibility-sensor";
import axios from "axios";
import Contact from '../../assets/image/contact_form.svg'
import { useMediaQuery } from "react-responsive";

const ContactForm = () => {
	const { register, errors, handleSubmit, reset } = useForm();
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const [success_popup, SetSuccess_popup] = useState(false);

	const [values, setValues] = useState({
		name: "",
		email: "",
		subject: "",
		message: "",
		success: false,
	});

	const { name, email, subject, message, error, success } = values;

	const handleChange = (name) => (event) => {
		setValues({ ...values, error: false, [name]: event.target.value });
	};

	const toastifySuccess = () => {
		toast.success("Form sent!", {
			position: "bottom-right",
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	const toastifyfail = () => {
		toast.error("Please enter vaild  details", {
			position: "bottom-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	const toastifyemail = () => {
		toast.warn("Please enter vaild mail-id", {
			position: "bottom-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	function sendEmail(e) {
		e.preventDefault();

		if ((values.name && values.email && values.message != "") || null) {
			if (validator.isEmail(email)) {
				// emaillogin(email);
                SetSuccess_popup(true)
				axios({
					method: "POST",
					url: "https://us-central1-larangel-6386d.cloudfunctions.net/app/api/sendmail",
					data: values,
                    crossorigin:true
				}).then((response) => {
					console.log(response.data);
					if (response.data === "success!") {
						setValues({
							name: "",
							email: "",
							subject: "",
							message: "",
							success: false,
						});
                        SetSuccess_popup(false)
						toastifySuccess();
					} else if (response.data === "fail!") {
						alert("Message failed to send.");
					}
				});
			} else {
				toastifyemail();
			}
		} else {
			toastifyfail();
		}
	}




	return (
		<>
		<div id="ContactUs"></div>
		<div style={{ height: isDesktopOrLaptop && "145vh" }} className="one ">
				<div className="OneStopText">
					<span className="part1">Contact Us</span>
				</div>
				<div className="row mt-2 justify-content-center">
                
					<div className="col-lg-6 col-12  p-lg-5 pr-4 pl-4" style={{zIndex:'10'}}>
								<form id="contact-form" onSubmit={sendEmail} noValidate>
									<div className="row formRow ">
										<div className="col-lg-6 col-12">
											<input
												type="text"
												name="name"
												value={name}
												onChange={handleChange("name")}
												className="form-control  formInput"
												placeholder="Name"
											></input>
										</div>

										<div className="col-lg-6 col-12 pt-lg-2 pt-3">
											<input
												type="email"
												name="email"
												value={email}
												onChange={handleChange("email")}
												className="form-control  formInput"
												placeholder="Email address"
											></input>
										</div>
									</div>

									<div className="row mt-3 formRow">
										<div className="col">
											<input
												type="text"
												name="subject"
												value={subject}
												onChange={handleChange("subject")}
												className="form-control  formInput"
												placeholder="Subject"
											></input>
										</div>
									</div>

									<div className="row  mt-3 formRow">
										<div className="col">
											<textarea
												rows={6}
												name="message"
												value={message}
												onChange={handleChange("message")}
												className="form-control  formInput"
												placeholder="Message"
											></textarea>
										</div>
									</div>

									<div className="text-center ">
										<input
											type="submit"
                                            disabled={success_popup}
											value={ success_popup ? 'sending...' : "send" }
											className="btn btn-dark btn-small btn-block mt-3 "
										/>
									</div>
								</form>
							</div>

                            <div className="col-12 text-center" style={{marginTop: isDesktopOrLaptop ? '-230px' : '10px'}}>
                    <img
                  src={Contact}
                  className='mt-4'
                  width='90%'
                />
                </div>

							<ToastContainer />
						</div>
					</div>
			
		</>
	);
};

export default ContactForm;
