import React, { useState, useEffect } from "react";
import { Link, Prompt, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import footer_logo from "../../assets/image/Larangel.svg";
import { useMediaQuery } from "react-responsive";
import { FiFacebook,FiInstagram, } from 'react-icons/fi';

export default function Footer(props) {
	const tab_or_smaller = useMediaQuery({ query: "(min-width: 900px)" });

	return (
		<>
        
			<div className="pt-lg-2 pb-4  ">
				<div className="row justify-content-center  pt-lg-5 pt-md-3 pb-3 rounded">
					<div className="col-lg-auto col-12 pr-lg-5 pb-2 mt-4 text-center ">
						<Link to="/">
							<LazyLoadImage src={footer_logo} className="footer_logo pr-lg-5" />
						</Link>
					</div>
					<div className="col-auto pl-lg-0 pl-5">
					
							<div className="row">
                            <div className="col-lg-auto  col-6 pr-5">
									<div className="footer_heading">Services</div>
									<Link className="footer_description" to="/">
										UX/UI
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
									  Content creation
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Digital Marketing
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Adv Backends
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Testing
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Devops
									</Link>
									<br></br>
								</div>

								<div className="col-lg-auto  col-6 pr-5">
									<div className="footer_heading">Products</div>
									<Link className="footer_description" to="/">
										Web Apps
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Mobile Apps
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										CRM Softwares
									</Link>
									<br></br>
								</div>

								<div className="col-lg-auto  col-6 pr-5 mt-4 mt-lg-0">
									<div className="footer_heading">TECHNOLOGY</div>
									<Link className="footer_description" to="/">
										MERN Stack
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Django & Vue js
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Firebase 
									</Link>
									<br></br>
								</div>

								<div className="col-lg-auto  col-6 pr-5 mt-4 mt-lg-0">
									<div className="footer_heading">Features</div>
									<Link className="footer_description" to="/">
										Data Security
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Responsiveness
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Design revisions
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										CMS
									</Link>
									<br></br>
								</div>
                                {tab_or_smaller && (


								<div className="col-auto  pr-5 ">
									<div className="footer_heading">ABOUT Us</div>
									<Link className="footer_description" to="/">
									   Leadership
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Core Values
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Team
									</Link>
									<br></br>
								</div>
                                )}
							</div>
					</div>
                    						


					<div className="col-12 pr-5 pl-5">
                    <hr></hr>
						<div className=" d-flex  ">
							<div className="footer_description flex-grow-1" to="/">
								© 2021 Larangel
							</div>
							<div className="footer_description" to="/">
                            <FiInstagram size='22px' color='#a670fc'/>
                            <FiFacebook size='22px' color='#a670fc'/>
							</div>
							<br></br>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
