import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/homepage/Home.css";
import "../../assets/css/general.css";
import "../../assets/css/Card/uiux.css";
import "bootstrap/dist/css/bootstrap.min.css";


export default function Card(props) {
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });


  return (
    <>
 <div class="uiux-card pr-lg-5 pl-lg-5 pr-md-3 pl-md-3" >
					
                      <div class="uiux-body">
                        <div class="svg-img-uiux pb-5 " style={{height:'145px'}}>
                          <img src={props.img} alt="IMAGE" className="img-fluid"/>
                        </div>
                        <div class="cardheadsection p-lg-2">
                      <div class="uiux-header">
                       {props.heading}
					</div>
                        <div class="uiux-sub-header">{props.subheading}</div>
					  </div>
                      </div>
                    </div>
    </>
  );
}
