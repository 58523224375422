import firebase from "firebase";
import 'firebase/auth'


const firebaseConfig = {

  apiKey: "AIzaSyA7h-PYwaMyUVYjYW9TMZnIxWS_6oKsYHY",

  authDomain: "larangel-6386d.firebaseapp.com",

  projectId: "larangel-6386d",

  storageBucket: "larangel-6386d.appspot.com",

  messagingSenderId: "1071175776696",

  appId: "1:1071175776696:web:a1787507d498ab77cf774d",

  measurementId: "G-LFLFBFDW1X"

};

    firebase.initializeApp(firebaseConfig);
     firebase.analytics();

     const storage = firebase.storage()

     const db = firebase.firestore();


  export  {
   db , storage , firebase
 }